<template>
  <div class="contractpreview">
    <NavBar name="合同概览"></NavBar>
    <div class="public-box">
      <div class="top">
        <div class="left-top">
          <div class="title">基本信息</div>
          <div class="percent" v-if="detail">
            <span>履约合同商户数</span>
            <el-progress
              :percentage="50"
              :text-inside="true"
              :format="percent"
              :stroke-width="15"
              color="#3DDDFF"
            ></el-progress>
          </div>
          <!-- <div class="percent" v-if="detail">
            <span>商户总数</span>
            <el-progress
              :percentage="100"
              :text-inside="true"
              :format="merchantsTotal"
              :stroke-width="15"
              color="#3D7EFF"
            ></el-progress>
          </div> -->
        </div>
        <div class="right-top">
          <div class="title">待办事件</div>
          <div class="item-box">
            <div class="item">
              <div class="count">{{ detail && detail.agencyInfo.expire_merchants_total || 0 }}</div>
              <div class="name">
                <img src="../../../assets/img/contractview/jjdq.png" />
                <span>合同即将到期商户</span>
                <img class="set" @click="getDetails" src="../../../unit/img/popset.png" alt="">
              </div>
            </div>
            <!-- <div class="item">
              <div class="count">{{ detail && detail.agencyInfo.renewal_merchants_total || 0 }}</div>
              <div class="name">
                <img src="../../../assets/img/contractview/xqht.png" />
                <span>续签合同商户</span>
              </div>
            </div> -->
            <div class="item">
              <div class="count">{{ detail && detail.agencyInfo.agree_merchants_total || 0 }}</div>
              <div class="name">
                <img src="../../../assets/img/contractview/dsp.png" />
                <span>待处理合同</span>
              </div>
            </div>
            <!-- <div class="item">
              <div class="count">{{ detail && detail.agencyInfo.change_merchants_total || 0 }}</div>
              <div class="name">
                <img src="../../../assets/img/contractview/htbg.png" />
                <span>合同变更申请</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="nav">
          <div class="left">
            <span class="title">合同签订趋势图</span>
            <el-date-picker
              v-model="year"
              format="yyyy"
              @change="contractChange"
              value-format="yyyy"
              type="year"
              placeholder="选择年"
            ></el-date-picker>
          </div>
          <div class="right">
            <div>
              <span class="dots first"></span>
              <span>合同商户数</span>
            </div>
            <div>
              <span class="dots second"></span>
              <span>新签合同商户数</span>
            </div>
            <div>
              <span class="dots third"></span>
              <span>合同终止商户数</span>
            </div>
          </div>
        </div>
        <div class="echarts">
          <div id="echarts" ref="echarts"></div>
        </div>
        <div class="table-box">
          <div class="title">
            <div></div>
            <div>已签合同商户数</div>
            <div>新签合同商户数</div>
            <div>终止合同商户数</div>
          </div>
          <div class="tableRight" v-if="detail">
            <div class="listBox" v-for="(item, index) in detail.contractStatics" :key="index">
              <div class="list">{{ getMonth(item.month) }}</div>
              <div class="list">{{ item.merchants_total }}</div>
              <div class="list">{{ item.new_merchants_total }}</div>
              <div class="list">{{ item.stop_merchants_total }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- 合同到期预警 -->
  <Confirm ref="contractexpireVue" :disabled="!(contractWarningSetting.values && contractWarningSetting.values.surplus_day)" type="slot" @confirm="confirmset">
    <template slot="content">
      <contractexpireVue :data="contractWarningSetting.values"></contractexpireVue>
    </template>
  </Confirm>
  </div>
</template>
<script>
// /setingchild/contractexpire.vue
import Confirm from "../../../unit/components/confirm.vue";
import contractexpireVue from "../../contract/setting/setingchild/contractexpire.vue";
export default {
  components:{
    contractexpireVue,
    Confirm
  },
  data() {
    return {
      list: [{
        name: '合同概览'
      }],
      year: (new Date().getFullYear()).toString(),
      detail: null,
       contractWarningSetting: {
        key: "contractWarningSetting",
        describe: "合同到期预警设置",
        values: {
          surplus_day: 40, //提前多少天提醒
          surplus_status: "", //
          content: ""
        },
      },
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    contractChange() {
      this.getDetail();
    },
    getDetail() {
      this.$request.HttpGet('/contract/contractStatis', {
        year: this.year
      }).then(res => {
        let merchants_total = [];
        let new_merchants_total = [];
        let stop_merchants_total = [];
        res.data.contractStatics.forEach(item => {
          merchants_total.push(item.merchants_total);
          new_merchants_total.push(item.new_merchants_total);
          stop_merchants_total.push(item.stop_merchants_total);
        });
        res.data.echarts = {
          merchants_total,
          new_merchants_total,
          stop_merchants_total
        };
        this.detail = res.data;
        this.draw(res.data);
      });
    },
    percent() {
      return this.detail.essentialInfo.before_merchants_total;
    },
    merchantsTotal() {
      return this.detail.essentialInfo.merchants_total;
    },
    getMonth(month) {
      switch (parseInt(month)) {
        case 1:
          return '一月';
        case 2:
          return '二月';
        case 3:
          return '三月';
        case 4:
          return '四月';
        case 5:
          return '五月';
        case 6:
          return '六月';
        case 7:
          return '七月';
        case 8:
          return '八月';
        case 9:
          return '九月';
        case 10:
          return '十月';
        case 11:
          return '十一月';
        case 12:
          return '十二月';
        default:
          return '';
      }
    },
    draw(data) {
      let myecharts = this.$echarts.init(this.$refs.echarts);
      let option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '合同商户数',
          type: 'line',
          color: '#02CE80',
          data: data.echarts.merchants_total,
          smooth: true,
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#297AFE'
              },
              {
                offset: 1,
                color: '#EFF5FF'
              }
              ], false)
            }
          },
          lineStyle: {
            color: '#297AFE'
          }
        },
        {
          name: '新签合同商户数',
          type: 'line',

          smooth: true,
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#02CE80'
              },
              {
                offset: 1,
                color: '#F6FDFA'
              }
              ], false)
            }
          },
          lineStyle: {
            color: '#02CE80'
          },
          data: data.echarts.new_merchants_total
        },
        {
          name: '终止合同商户数',
          type: 'line',

          smooth: true,
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#F2884A'
              },
              {
                offset: 1,
                color: '#F2FDF9'
              }
              ], false)
            }
          },
          lineStyle: {
            color: '#F2884A'
          },
          data: data.echarts.stop_merchants_total
        }
        ]
      };
      myecharts.setOption(option);
    },
    getDetails(type) {
      this.$request.HttpGet(`/setting/edit?key=contractWarningSetting`).then((res) => {
        if (res.data && res.data.values) {
          this.contractWarningSetting.values = res.data.values;
          this.$refs.contractexpireVue.fade()
        }
      });
    },
     confirmset(type) {
      if(this.contractWarningSetting.values &&  this.contractWarningSetting.values.surplus_day){
        this.contractWarningSetting.values.surplus_day *=1
      }
      this.$request.HttpPost('/setting/add', this.contractWarningSetting).then(res => {
        this.$common.notifySuccess(res.msg)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.public-box {
  .top {
    display: flex;
    align-items: center;

    .left-top {
      // width: 576px;
      height: 200px;
      background: #ffffff;
      border: 1px solid #dedede;
      padding: 19px;
      box-sizing: border-box;
      flex: 0.5;
      .title {
        margin-bottom: 53px;
      }
      .percent {
        // width: 300px;
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        span {
          display: inline-block;
          // width: 80px;
          text-align: right;
        }
        .el-progress {
          width: 350px;
          margin-left: 14px;
        }
        /deep/ .el-progress-bar__outer {
          background-color: transparent;
        }
      }
    }
    .right-top {
      // width: 1094px;
      height: 200px;
      background: #ffffff;
      border: 1px solid #dedede;
      margin-left: 20px;
      padding: 19px;
      box-sizing: border-box;
      flex: 1;
      .item-box {
        width: 100%;
        margin-top: 29px;
        padding: 0 80px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .item {
          height: 88px;
          .count {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            margin-bottom: 19px;
            padding-left: 26px;
            box-sizing: border-box;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
        .set{
          cursor: pointer;
          margin-left: 5px;
        }
      }
    }
  }
  .bottom {
    height: 579px;
    background: #ffffff;
    border: 1px solid #dedede;
    margin-top: 20px;
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 29px 40px 30px 20px;
      box-sizing: border-box;
      .title {
        margin-right: 30px;
      }
      .right {
        display: flex;
        div {
          margin-right: 30px;
          .first {
            background-color: #297afe;
          }
          .second {
            background-color: #02ce80;
          }
          .third {
            background-color: #f2884a;
          }

          &:nth-child(4) {
            span {
              span {
                color: #000000;
                opacity: 0.6;
              }
            }
          }
        }
        .dots {
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #297afe;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
    .echarts {
      width: 100%;
      height: 300px;
      padding: 0 107px 0 110px;
      box-sizing: border-box;
      #echarts {
        height: 100%;
      }
    }
    .table-box {
      padding: 0 107px 0 45px;
      box-sizing: border-box;
      display: flex;
      .title {
        div {
          height: 37px;
          line-height: 37px;
          text-align: right;
          padding-right: 29px;
          box-sizing: border-box;
          // margin-right: 15px;
        }
      }
      .tableRight {
        display: flex;
        flex: 1;
        justify-content: space-between;
        border-left: 1px solid #f5f5f5;
        border-top: 1px solid #f5f5f5;
        .listBox {
          // max-width: 124px;
          // min-width: 117px;
          flex: 1;
          text-align: center;
          box-sizing: border-box;
          border-right: 1px solid #f5f5f5;
          .list {
            width: 100%;
            height: 37px;
            line-height: 37px;
            border-bottom: 1px solid #f5f5f5;
          }
        }
      }
    }
  }
}
</style>
