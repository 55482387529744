<template>
  <div>
    <!-- <Navlid :list="navList"></Navlid> -->
    <NavBar :list="navList" name="合同管理"></NavBar>
    <div class="addcontract" v-loading="loading">
      <el-form label-position="right" :model="form" ref="form" :rules="rules" label-width="180px">
        <el-form-item label="签订方式：" prop="contract_type">
          <el-radio-group v-model="form.contract_type" :disabled="forbid">
            <el-radio :label="10">线上签订合同</el-radio>
            <el-radio :label="20">线下已签合同</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合同模板:" prop="property_tpl_id">
          <el-select v-model="form.property_tpl_id" :disabled="forbid" placeholder="请选择合同模板" @change="changeContract"
            class="labelwidth">
            <el-option v-for="item in contractTemplate" :key="item.property_setting_id" :value="item.property_setting_id"
              :label="item.values.name"></el-option>
          </el-select>
          <span class="public-tips">
            提示：合同模板在合同管理-合同设置中进行添加或修改
            <span @click="
              $router.push('/contract/contractSetting/contractsSetting')
              ">点击前往</span>
          </span>
        </el-form-item>
        <el-form-item label="合同编号：" v-if="form.contract_type == 20" prop="contract_no">
          <el-input placeholder="合同编号" v-model="form.contract_no" :disabled="forbid" class="labelwidth"></el-input>
        </el-form-item>
        <el-form-item label="合同文件：" v-if="form.contract_type == 20">
          <el-upload class="upload-demo" ref="upload" action :disabled="forbid" :show-file-list="false"
            :on-change="handlePreview" :auto-upload="false" accept=".pdf" v-if="!forbid">
            <!-- <span
                  @click.stop="jumImg(form.contract_tpl)"
                  v-if="form.contract_tpl"
                  style="marginRight: 10px;display: block"
              >{{ form.contract_tpl }}</span>-->
            <span v-if="form.contract_tplName" style="marginright: 10px; display: block">{{ form.contract_tplName
            }}</span>
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
          <el-button type="primary" @click="checktpl(form.contract_tpl)" v-else>查看合同文件</el-button>
        </el-form-item>
        <el-form-item label="合同文件：" v-if="form.contract_type == 10 && form.contract_address">
          <el-button type="primary" @click="checktpl(form.contract_tpl)">查看合同文件</el-button>
        </el-form-item>
        <el-form-item label="商户:" prop="merchants_id">
          <el-select v-model="form.merchants_id" :disabled="forbid" @change="changeMerchants" placeholder="请选择商户"
            class="labelwidth">
            <el-option v-for="item in merchantsList" :key="item.merchants_id" :value="item.merchants_id"
              :label="item.contact_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证件号:">
          <el-input placeholder="证件号" v-model="iDNumber" :disabled="true" class="labelwidth"></el-input>
        </el-form-item>
        <!-- <el-form-item label="意向金转余额：" v-if="type != 'sp' || !type">
          <el-input v-model="contractSpecial" :disabled="forbid" placeholder="请输入意向金凭证号" clearable class="labelwidth">
          </el-input>
          <MyButton left v-if="!forbid" @click="inquire">查询</MyButton>
          <span class="public-tips">
            提示：意向金在收费管理-意向金中进行添加
            <span @click="$router.push('/charge/intention')">点击前往</span>
          </span>
        </el-form-item>
        <el-form-item label="意向金金额：" v-if="type != 'sp' || !type">
          <el-input :disabled="true" v-model="contractSpecialPrice" class="labelwidth"></el-input>
        </el-form-item> -->
        <el-form-item label="合同开始时间:" prop="contract_start">
          <el-date-picker class="labelwidth" :disabled="forbid" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            @change="contractStart" v-model="form.contract_start" placeholder="合同开始时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="合同到期时间:" prop="contract_end">
          <el-date-picker class="labelwidth" :disabled="forbid" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            :picker-options="pickerOptions" @change="calculateTimeChange" v-model="form.contract_end"
            placeholder="合同到期时间"></el-date-picker>
        </el-form-item>
        <el-divider content-position="left">商铺租赁</el-divider>
        <div v-if="form.property_tpl_id && form.contract_fee_setting && form.contract_fee_setting.length > 0">
          <div v-for="(item, index) in form.contract_fee_setting" :key="index">
            <div class="rentalBox">
              <div class="left">
                <div class v-for="(list, j) in item" :key="j">
                  <el-form-item label="租赁商铺" v-if="j == 0"
                    :prop="`contract_fee_setting[${index}][${j}].merchants_stall_id`" :rules="{
                      required: true,
                      message: '请选择租赁商铺',
                      trigger: 'change',
                    }">
                    <el-select v-model="list.merchants_stall_id" :disabled="forbid"
                      @change="merchantsChange(list, item, index)" class="labelwidth" clearable placeholder="请选择商铺">
                      <el-option v-for="shop in shopLists" :disabled="list.disabled && list.pitchVal != index"
                        :key="shop.merchants_stall_id" :label="shop.merchants_stall_number"
                        :value="shop.merchants_stall_id"></el-option>
                    </el-select>
                    <span class="public-tips">
                      提示：租赁商铺在商铺管理-商铺设置中进行添加
                      <span @click="$router.push('/merchant/merchantsetting')">点击前往</span>
                    </span>
                  </el-form-item>
                  <el-form-item :label="list.contract_fee_name" v-else
                    :prop="`contract_fee_setting[${index}][${j}].pay_price`" :rules="{
                      required: list.required_type == 10 ? true : false,
                      message: `请输入${list.contract_fee_name}`,
                      trigger: 'blur',
                    }">
                    <el-input :placeholder="list.contract_fee_name" :disabled="forbid" type="number"
                      @input="calculate(list, j, item, index)" v-model="list.pay_price" class="labelwidth"></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- <div class="right">
                <MyButton type="error" @click="delRental(index)" v-if="!forbid" :disabled="iscomplete">删除</MyButton>
              </div> -->
            </div>
            <el-form-item>
              <el-divider></el-divider>
            </el-form-item>
          </div>
        </div>
        <!-- <el-form-item>
          <MyButton @click="addRental">添加</MyButton>
        </el-form-item> -->

        <el-divider content-position="left" v-if="form.monthTotal && form.monthTotal.length > 0">缴费金额（此栏金额与商铺租赁数据关联）
        </el-divider>
        <el-row>
          <el-col :span="5" v-for="(item, index) in form.monthTotal" :key="index">
            <el-form-item :label="`每月总${item.contract_fee_name}:`" :prop="`monthTotal[${index}].month_pay_price`" :rules="{
              required: item.required_type == 10 ? true : false,
              message: `请输入月总${item.contract_fee_name}`,
              trigger: 'blur',
            }">
              <el-input :placeholder="`月总${item.contract_fee_name}`" :disabled="forbid"
                @input="calculateBackstepping($event, item, index)" :ref="'change' + index"
                @blur="blurcalculateBackstepping($event, item, index)" :value="item.month_pay_price"
                class="public-input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="每月应缴费用合计:">
              <el-input placeholder="每月应缴费用合计" v-model="form.month_price" :disabled="true" class="public-input">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div>
          <div v-for="(item, index) in form.contract_increasing" :key="index">
            <el-form-item :label="`第${parseInt(index) + 1}年度`">
              <template v-if="index > 0">
                <div v-for="(list, j) in item.fees_list" :key="j" style="margin-bottom: 20px;">
                  <el-form-item :label="`${list.name}递增率`">
                    <el-input placeholder="递增率" :disabled="true" style="width: 250px; marginLeft:15px;"
                      v-model.trim="list.contract_increasing" class="input-with-select">
                      <el-select v-model="list.change" :disabled="true" style="width: 100px;" slot="prepend"
                        placeholder="请选择">
                        <el-option :value="1" label="百分比"></el-option>
                        <el-option :value="2" label="固定金额"></el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </div>
                <!-- <span>
                  递增费用
                  <el-select v-model="item.type" multiple>
                    <el-option v-for="cost in form.monthTotal" disabled :key="cost.id" :value="cost.parent_id.toString()"
                      :label="cost.contract_fee_name"></el-option>
                  </el-select>
                </span>
                <span>
                  <el-input placeholder="递增率" style="width: 250px; marginLeft:15px;" disabled
                    v-model.trim="item.contract_increasing" class="input-with-select">
                    <el-select v-model="item.change" disabled style="width: 110px;" slot="prepend" placeholder="请选择">
                      <el-option :value="1" label="百分比"></el-option>
                      <el-option :value="2" label="固定金额"></el-option>
                    </el-select>
                  </el-input>
                </span> -->
              </template>
              <template v-if="index > 0">
                <div style="margin: 15px 0">
                  <span v-for="(fees, j) in item.fees_list" :key="j">
                    每月总{{ fees.name }}:
                    <el-input class="public-input" v-model="fees.pay_price" disabled></el-input>
                  </span>
                </div>
              </template>
              <div style="margin-bottom: 15px" v-if="index > 0">
                <span>每月应缴费用合计:</span>
                <el-input class="public-input" v-model="item.month_pay_price" disabled></el-input>
              </div>
              <div>
                <span>{{ `第${parseInt(index) + 1}年度费用合计` }}：</span>
                <el-input disabled v-model="item.total_pay_price" class="public-input"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="缴费项" type="card">
              <el-tabs v-model="item.activeName">
                <el-tab-pane :label="list.name" :name="k.toString()" v-for="(list, k) in item.fees_list" :key="k">
                  <el-form-item label="缴费周期">
                    <el-input placeholder="请输入缴费周期" :disabled="forbid" @blur="blurRentInfo(item, list, k)"
                      v-model="item.fees_list[k].contract_rent_total" type="number" class="labelwidth"></el-input>
                  </el-form-item>
                  <div v-if="list.contract_rentInfo.length > 0">
                    <div v-for="(pay, j) in list.contract_rentInfo" :key="j" style="margin-top: 14px;">
                      <div style="margin-bottom: 10px; display: flex;align-items: center;flex-wrap: wrap;">
                        <el-form-item label="开始时间:" style="flex: 1">
                          <el-date-picker v-model="pay.property_time" :disabled="forbid" class="public-input"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择开始时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束时间:" style="flex: 1">
                          <el-date-picker v-model="pay.pay_end_time" :disabled="forbid" class="public-input"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择缴费时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="缴费金额:" style="flex: 1">
                          <el-input placeholder="请输入缴费金额" :disabled="forbid"
                            @blur="amountPayment($event, pay, list, item)" v-model="pay.pay_price"
                            class="public-input"></el-input>
                        </el-form-item>
                      </div>
                      <div style="margin-bottom: 10px; display: flex;align-items: center;flex-wrap: wrap;">
                        <el-form-item label="约定缴费时间:" style="flex: 1">
                          <el-date-picker v-model="pay.pay_time" :disabled="forbid" class="public-input"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择缴费时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="实际缴费时间:" style="flex: 1">
                          <el-date-picker v-model="pay.reality_pay_time" :disabled="forbid" class="public-input"
                            :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            placeholder="请选择实际缴费时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="已缴费金额:" v-if="pay.old_pay != '0'" style="flex: 1">
                          <el-input placeholder="请输入缴费金额" :disabled="forbid" @blur="alreadyPay(pay)" v-model="pay.old_pay"
                            class="public-input"></el-input>
                        </el-form-item>
                      </div>
                      <div style="margin-bottom: 10px; display: flex;align-items: center;flex-wrap: wrap;">
                        <el-form-item label="减免:" style="flex: 1">
                          <el-checkbox v-model="pay.is_derate" :disabled="forbid">是否减免</el-checkbox>
                        </el-form-item>
                        <el-form-item label="减免开始时间:" v-if="pay.is_derate" style="flex: 1">
                          <el-date-picker v-model="pay.derate_start_time" :disabled="forbid" class="public-input"
                            :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            placeholder="请选择减免开始时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="减免结束时间:" v-if="pay.is_derate" style="flex: 1">
                          <el-date-picker v-model="pay.derate_end_time" class="public-input" :disabled="forbid"
                            :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            placeholder="请选择减免结束时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="减免金额:" v-if="pay.is_derate" style="flex: 1">
                          <el-input placeholder="请输入缴费金额" v-model="pay.derate_pay" :disabled="forbid"
                            class="public-input"></el-input>
                        </el-form-item>
                      </div>

                    </div>
                    <el-form-item v-if="item.show">
                      <span style="color: red">多次缴费金额之和等于应缴总金额</span>
                    </el-form-item>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-form-item>
          </div>
          <el-row>
            <el-col :span="5" v-for="(item, index) in form.monthTotal" :key="index">
              <el-form-item :label="`租期内总${item.contract_fee_name}`">
                <el-input-number :placeholder="`租期内总${item.contract_fee_name}`" :disabled="forbid" :controls="false"
                  :min="0" type="Number" @blur="calculateTotalData($event, index)" v-model="item.total_pay_price"
                  class="public-input">
                </el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="总金额:">
                <el-input placeholder="总金额" v-model="form.total_price" :disabled="true" class="public-input"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">合同数据</el-divider>
        <el-row>
          <el-col :span="5">
            <el-form-item label="违约金：">
              <el-input v-model="form.contract_overdue" clearable :disabled="forbid" class="inputWidth">
                <template slot="prepend">每逾期</template>
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-input v-model="form.contract_overdue_value" :disabled="forbid" clearable>
                <template slot="prepend">按照逾期支付费用的</template>
                <template slot="append">%违约金</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="经营范围：">
          <el-input v-model="form.contract_manage" :disabled="forbid" placeholder="请输入经营范围" clearable
            class="labelwidth"></el-input>
        </el-form-item>
        <el-form-item label="特别约定：">
          <el-input v-model="form.contract_special" :rows="3" type="textarea" :disabled="forbid" placeholder="请输入特别约定"
            clearable class="labelwidth"></el-input>
        </el-form-item>
        <!--固定缴费费用-->
        <templateField v-if="form.dcList.length > 0" :dcList="form" :list="form.contract_fee_setting"
          :disabled="forbid" />

        <el-form-item v-if="id && !type">
          <MyButton @click="dialogVisible = true" right>拒绝</MyButton>
          <MyButton @click="agree" :disabled="iscomplete">同意</MyButton>
        </el-form-item>
        <el-form-item v-else-if="id && type == 'edit'">
          <MyButton @click="$router.go(-1)" right>取消</MyButton>
          <MyButton @click="submit" right :disabled="iscomplete">重新提交</MyButton>
        </el-form-item>
        <el-form-item v-else>
          <MyButton @click="submit" :disabled="iscomplete">提交</MyButton>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog title="拒绝" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="refuseRules" ref="refuseRuleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="拒绝原因" prop="contract_desc">
          <el-input v-model="ruleForm.contract_desc" class="public-input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton @click="dialogVisible = false" right>取消</MyButton>
        <MyButton @click="refuse" :disabled="iscomplete">确定</MyButton>
      </span>
    </el-dialog>
    <!-- <el-dialog :close-on-click-modal="false" title="合同文件" :visible.sync="contractdialogVisible" top="1vh" width="80vw">
      <div v-if="form.contract_address" v-html="form.contract_content"></div>
    </el-dialog> -->
  </div>
</template>
<script>
import templateField from "./components/templateField0";

export default {
  name: "addedcontract",
  components: {
    templateField,
  },
  data () {
    return {
      navList: [
        {
          name: "合同审核",
        },
      ],
      contractdialogVisible: false,
      imgUrl: "",
      form: {
        contract_fee_setting: [],
        dcList: [],
        property_tpl_id: "", //合同模板id
        contract_type: "", //签订方式
        merchants_id: "", //商户
        contract_start: "",
        contract_end: "",
        contract_rent_total: "",
        contract_rent_info: "",
        contractRentInfo: [],
        contract_special: "",
        contract_manage: "",
        total_price: "",
        month_price: "",
        monthTotal: [],
        contract_no: "",
        contract_tpl: "",
        contract_tplName: "",
        purpose_id: "",
        merchants_stall: [],
        contract_fee_start: "",
        contract_fee_end: "",
        rent_holiday: "",
        // 每年月费用
        contract_increasing: [],
      },
      // 时间段可选择商户,
      dateedShop: [],
      // 意向金商铺,
      intentionshop: [],
      rules: {
        contract_no: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
          },
        ],
        property_tpl_id: [
          {
            required: true,
            message: "请选择合同模板",
            trigger: "change",
          },
        ],
        contract_type: [
          {
            required: true,
            message: "请选择合同签订方式",
            trigger: "change",
          },
        ],
        merchants_id: [
          {
            required: true,
            message: "请选择商户",
            trigger: "change",
          },
        ],
        contract_start: [
          {
            required: true,
            message: "请选择合同开始时间",
            trigger: "change",
          },
        ],
        contract_end: [
          {
            required: true,
            message: "请选择合同结束时间",
            trigger: "change",
          },
        ],
        contract_rent_total: [
          {
            required: true,
            message: "请输入缴费次数",
            trigger: "blur",
          },
        ],
      },
      progressiveIncreaseRate: {
        cost: [],
        startTime: "",
        intervalTime: "",
        percentage: "",
      },
      iDNumber: "",
      contractSpecial: "",
      contractSpecialPrice: "",
      contractTemplate: [],
      shopList: [],
      contractChargeSetList: [],
      merchantsList: [],
      disabled: false,
      forbid: false,
      id: "",
      type: "",
      rent_holiday_date: [],
      pickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
      purposeList: [],
      dialogVisible: false,
      ruleForm: {
        contract_desc: "",
      },
      refuseRules: {
        contract_desc: [
          {
            required: true,
            message: "请输入拒绝原因",
            trigger: "blur",
          },
        ],
      },
      iscomplete: false,
      ContractID: "",
      loading: false,
      // 但年计算费用
      years: 0, //年数
      contract_is_agree: "",
    };
  },
  created () {
    this.imgUrl = process.env.VUE_APP_LOGIN;
    this.contract_is_agree = this.$route.query.status;
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.forbid = this.$route.query.forbid ? true : false;
    this.getData();
  },
  computed: {
    shopLists () {
      let arr = [...this.dateedShop, ...this.intentionshop];
      return arr;
    },
  },
  watch: {
    "form.monthTotal": {
      deep: true,
      handler: function (ev, ol) {
        if (!ev || ev.length == 0) {
          return;
        }
        for (let i = 0; i < ev.length; i++) {
          let target = ev[i].month_pay_price.toString();
          let indexs = target.indexOf(".");
          let change = "change" + i;
          if (!this.$refs[change]) {
            return false;
          }
          this.$refs[change][0].$el.children[0].style.border =
            "1px solid #C0C4CC";
          if (indexs > -1) {
            let len = target.slice(indexs, target.length - 1).length;
            //小数点大于2位
            if (len > 2) {
              this.$refs[change][0].$el.children[0].style.border =
                "1px solid red";
              // this.$refs[change][0].$el.style.border = '1px solid red'
            }
          }
        }
      },
    },
  },
  methods: {
    setDisabled (item, index, parentIndex) {
      if (this.forbid) {
        return true;
      }
      if (index == 0 && parentIndex == 0) {
        return false;
      }
      if (index > 0 && !item[index - 1].pay_time) {
        return true;
      }
      if (index == 0 && parentIndex >= 0) {
        let parentData = this.form.contract_increasing[parentIndex - 1];
        if (parentData.contract_rentInfo.length == 0) {
          return true;
        } else {
          let len = parentData.contract_rentInfo.length - 1;
          if (parentData.contract_rentInfo[len].pay_time) {
            return false;
          } else {
            return true;
          }
        }
      }
      return false;
    },
    pickerOptionsStart (item, index, parentIndex) {
      return {
        disabledDate: (time) => {
          if (index == 0 && parentIndex == 0 && this.form.contract_start) {
            let endTime = new Date(this.form.contract_start).getTime();
            return time.getTime() < endTime;
          } else if (index == 0) {
            let len =
              this.form.contract_increasing[parentIndex - 1].contract_rentInfo
                .length - 1;
            let endTime = new Date(
              this.form.contract_increasing[parentIndex - 1].contract_rentInfo[
                len
              ].pay_time
            ).getTime();
            return time.getTime() < endTime;
          } else {
            let endTime = new Date(item[index - 1].pay_time).getTime();
            return time.getTime() < endTime;
          }
        },
      };
    },
    timeChange (evet, item) {
      item.pay_time = evet;
      this.$forceUpdate();
    },
    async getData () {
      await this.getContractTemplate();
      await this.getContractChargeSetList();
      await this.getMerchantsList();
      if (this.id) {
        this.loading = true;
        this.disabled = true;
        await this.getDetails();
      }
    },
    jumImg (url) {
      let isUrl = url.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        window.open(process.env.VUE_APP_LOGIN + "/" + url);
      } else {
        window.open(url);
      }
    },
    refuse () {
      this.iscomplete = true;
      this.$refs.refuseRuleForm.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/contract/isAgreeSave", {
              contract_id: this.id,
              contract_desc: this.ruleForm.contract_desc,
              contract_is_agree: 20,
            })
            .then((res) => {
              this.dialogVisible = false;
              this.$common.notifySuccess(res.msg);
              this.$router.go(-1);
            })
            .finally(() => {
              this.iscomplete = false;
            });
        } else {
          this.iscomplete = false;
        }
      });
    },
    handleClose () {
      this.ruleForm.contract_desc = "";
    },
    // 同意
    agree () {
      this.iscomplete = true;
      this.$request
        .HttpPost("/contract/isAgreeSave", {
          contract_id: this.id,
          contract_is_agree: this.contract_is_agree,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.$router.go(-1);
        })
        .finally(() => {
          this.iscomplete = false;
        });
    },
    dealDisabledDate (time) {
      return time.getTime() < new Date(this.form.contract_start).getTime();
    },
    // 查询意向金
    inquire () {
      // this.shopList = [];

      if (this.id) {
        return;
      }
      if (!this.contractSpecial) {
        this.$common.notifyError("请输入意向金凭证号");
        return;
      }
      this.$request
        .HttpGet("/purpose/getPurposeNo", {
          purpose_no: this.contractSpecial,
        })
        .then(async (res) => {
          if (res.data.pay_status == 0 && res.data.purpose_status != -1) {
            this.$common.notifyError("该意向金还未支付，无法使用");
          } else {
            if (res.data.purpose_status != 0) {
              this.$common.notifyError("该意向金已使用或者已作废");
            } else {
              this.form.purpose_id = res.data.purpose_id;
              this.contractSpecialPrice = res.data.purpose_money;
              // this.purposeList = res.data.merchants_purpose_stall;
              if (!this.form.contract_start) {
                this.form.contract_start = res.data.purpose_end_time;
                await this.contractStart(res.data.purpose_end_time, true);
              }
              if (this.form.contract_end) {
                await this.contractStart(res.data.purpose_end_time, true);
              }
              this.intentionshop = res.data.merchants_purpose_stall;
              // this.shopList = this.shopList.concat(res.data.merchants_purpose_stall);
              // 判断有没有选择商铺
              if (
                !this.form.contract_fee_setting.length <= 0 &&
                !this.form.contract_fee_setting[0][0].merchants_stall_id
              ) {
                let tempArr = this.form.contract_fee_setting.splice(0, 1);
                this.automaticSetPurpose(
                  res.data.merchants_purpose_stall,
                  tempArr
                );
              } else {
                let tempArr = JSON.stringify(this.form.contract_fee_setting);
                this.automaticSetPurpose(
                  res.data.merchants_purpose_stall,
                  JSON.parse(tempArr)
                );
              }
            }
          }
        });
    },
    automaticSetPurpose (data, tempArr) {
      this.form.contract_fee_setting = [];
      for (let i in data) {
        let arr = [];
        for (let j in tempArr[0]) {
          let strObj = tempArr[0][j];
          if (j == 0) {
            strObj = data[i];
          }
          if (j == 1) {
            strObj.pay_price = data[i].merchants_stall_price;
          }
          arr.push({
            ...strObj,
          });
        }
        this.form.contract_fee_setting.push(arr);
      }
    },
    // 上传合同
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request
          .HttpPost("/system/upload/upFile", formData)
          .then((res) => {
            this.form.contract_tpl =
              process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
            this.form.contract_tplName = res.data.fileInfo.fileName;
          });
      }
    },
    // 获取合同详情
    getDetails () {
      this.$request.HttpGet("/contract/edit", {
        id: this.id,
      }).then(async (res) => {
        if (res.data) {
          try {
            res.data.contract_increasing = JSON.parse(res.data.contract_increasing);
          } catch (err) {
            res.data.contract_increasing = [];
            console.log("没有递增数据");
          }
          if (this.type == "update") {
            res.data.contract_no = "";
            res.data.contract_fee_setting = [];
            res.data.contract_increasing = [];
            res.data.dcList = [];
            res.data.contract_start = "";
            res.data.contract_end = "";
            res.data.contract_rent_info = "";
            res.data.monthTotal = [];
            res.data.contractRentInfo = [];
            res.data.month_price = "";
            res.data.total_price = "";
            res.data.contract_rent_total = "";
            res.data.merchants_stall = [];
            res.data.contract_tplName = "";
            this.form = res.data;
            this.form.contract_tpl = "";
            this.form.contract_tplName = "";
            this.ContractID = res.data.property_tpl_id;
            await this.changeContract(res.data.property_tpl_id);
          } else {
            // if (this.type == 'edit') {
            //   res.data.contract_no = '';
            // }
            if (this.type == "renew") {
              res.data.contract_rent_total = "";
              res.data.contract_no = "";
              res.data.contractRentInfo = [];
              res.data.contract_start = res.data.contract_end;
              res.data.contract_end = "";
              res.data.contract_rent_info = "";
              res.data.monthTotal = res.data.monthTotal || [];
              this.ContractID = res.data.property_tpl_id;
              if (res.data.dcList) {
                res.data.dcList.forEach((item) => {
                  item.alreadyPayCost = item.pay_price;
                  item.pay_price = "";
                  item.pay_time = "";
                  item.statuseds = false;
                });
              }
            } else {
              res.data.contractRentInfo = JSON.parse(
                res.data.contract_rent_info
              );
              if (res.data.contractRentInfo) {
                res.data.contractRentInfo.forEach((item) => {
                  if (item.bill_status == 10) {
                    item.statused = true;
                  } else {
                    item.statused = false;
                  }
                });
              }
            }
            await this.contractStart(res.data.contract_start, true);
            res.data.dcList = res.data.dcList || [];
            res.data.contract_tplName = "";
            this.form = res.data;
          }

          // if (this.form.contract_address) {
          //   this.form.contract_content = this.form.contract_content.replace(/&lt;商户签字&gt;/g, `<img style="width: 100px;height: 40px" src="${this.form.contract_address}" />`);
          // }

          //purpose_info

          if (this.type == "update") {
            this.contractSpecial = "";
            this.contractSpecialPrice = "";
          } else {
            this.contractSpecialPrice = 0;
            this.contractSpecial = "";
            let str = [];
            res.data.purpose_info &&
              res.data.purpose_info.forEach((item) => {
                str.push(item.purpose_no);
                this.contractSpecialPrice += item.purpose_money;
              });
            this.contractSpecial = str.join(",");
          }

          let date = this.$common.addMonth(
            this.form.contract_start,
            this.form.rent_holiday
          );
          this.form.contract_fee_start = this.form.contract_start;
          let end = this.$common.formatDateTime(date);
          this.$set(this.rent_holiday_date, 0, this.form.contract_start);
          this.$set(this.rent_holiday_date, 1, end);
          this.changeMerchants(res.data.merchants_id);
          this.loading = false;
        }
      });
    },
    // 重新计算所有值的金额
    calculateTimeChange (type) {
      // this.changeContract(this.ContractID)
      if (!type == "mrent") {
        this.form.rent_holiday = 0;
      }
      if (this.form.monthTotal) {
        this.form.monthTotal.forEach((item) => {
          item.month_pay_price = 0;
        });
      }
      if (this.form.contract_fee_setting.length > 0) {
        for (let i in this.form.contract_fee_setting) {
          let temp = this.form.contract_fee_setting[i];
          for (let j in temp) {
            if (j == 0) {
              continue;
            }
            if (this.form.monthTotal) {
              // (this.form.monthTotal[j - 1].month_pay_price * 100) +
              this.form.monthTotal[j - 1].month_pay_price +=
                (temp[j].pay_price * 100) / 100;
            }
          }
        }
        let time = this.$common.monthsBetw(
          this.form.contract_start,
          this.form.contract_end
        );
        time = time - (this.form.rent_holiday || 0);
        // for (let i in this.form.monthTotal) {
        //   this.form.monthTotal[i].total_pay_price =
        //     (this.form.monthTotal[i].month_pay_price * 100 * time) / 100;
        // }
        this.calculateTotal();
        this.years = Math.ceil(time / 12);
        if (this.form.contract_fee_setting[0][0].merchants_stall_id) {
          this.initYearMonthFees();
        }
      }
    },
    // 改变总的金额单项，来进行反推数据
    totalCalculateBackstepping (item, index) {
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      time = time - (this.form.rent_holiday || 0);
      if (!this.isMoney(item, "total_pay_price")) return false;
      item.month_pay_price = this.$common.getDecimalsOne(
        item.total_pay_price / time
      );
      this.backstepping(item, index);
    },
    // 改变月总的金额，来进行反推数据
    calculateBackstepping (target, item, index) {
      console.log(target);

      item.month_pay_price = target;
      if (!this.isMoney(item, "month_pay_price")) return false;
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      time = time - (this.form.rent_holiday || 0);
      let price = target ? target : 0;
      item.total_pay_price = this.$common.getDecimalsOne(
        (price * 100 * time) / 100
      );
      this.backstepping(item, index);
      this.initYearMonthFees();
    },
    // 时区焦点
    blurcalculateBackstepping (target, item, index) {
      if (!item.month_pay_price) {
        item.month_pay_price = 0;
        this.calculateBackstepping(0, item, index);
      }
    },
    backstepping (item, index) {
      let len = this.form.contract_fee_setting.length;
      if (len == 1) {
        index++;
        this.form.contract_fee_setting[0][index].pay_price = parseFloat(
          item.month_pay_price
        );
      } else {
        let average = Number(
          this.$common.getDecimalsOne(item.month_pay_price / len)
        ); // 获取当前平均值，并且小数后两位去掉
        let sy = 0;
        // 不能整除最后一项补;
        if (average * len != item.month_pay_price) {
          sy = Number((item.month_pay_price - average * len).toFixed(2));
        }
        for (let i in this.form.contract_fee_setting) {
          if (i == len - 1) {
            // 最后一个数据进行补缺
            this.form.contract_fee_setting[i][parseInt(index) + 1].pay_price = (
              average + sy
            ).toFixed(2);
          } else {
            this.form.contract_fee_setting[i][parseInt(index) + 1].pay_price =
              parseFloat(average);
          }
          this.calculateSingleTotal(this.form.contract_fee_setting[i]);
        }
      }
      this.calculateTotal();
    },
    // 计算对应的金额值，分别对应月和总  item-> 当前输入项  index-> 当前商铺大项   j -> 当前哪一个小项
    async calculate (item, j, list, index) {
      this.initYearMonthFees();
      if (!item.pay_price) {
        return;
      }
      this.form.contract_fee_setting[index][0].month_pay_price =
        list[1].pay_price;
      // item.pay_price = item.pay_price * 1;
      if (!this.form.contract_start) {
        this.$common.notifyError("请先选择合同开始时间");
        return;
      }
      if (!this.form.contract_end) {
        this.$common.notifyError("请先选择合同结束时间");
        return;
      }
      if (!this.isMoney(item, "pay_price")) return false;
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      time = time - (this.form.rent_holiday || 0);
      let data = this.form.monthTotal[j - 1];
      let monthVal = 0;
      for (let i in this.form.contract_fee_setting) {
        for (let k in this.form.contract_fee_setting[i]) {
          if (k == j) {
            monthVal += parseFloat(
              this.form.contract_fee_setting[i][k].pay_price * 100
            );
          }
        }
      }
      data.month_pay_price = monthVal / 100;
      // data.total_pay_price = (monthVal * time) / 100;
      this.calculateTotal();
      this.calculateSingleTotal(list);
    },
    /* eslint-enable */
    // 计算单个商铺租凭总和
    calculateSingleTotal (list) {
      let total = 0;
      for (let i in list) {
        if (i == 0) {
          continue;
        }
        total += list[i].pay_price * 100;
      }
      list[0].total_pay_price = this.$common.getDecimalsOne(total / 100);
    },
    isMoney (item, value) {
      if (isNaN(item[value])) {
        return false;
      }
      if (item[value] < 0) {
        item[value] = 0;
      }
      let str = item[value].toString();
      let index = str.indexOf(".");
      let len = 0;
      if (index > -1) {
        len = str.substr(index, str.length - 1).length;
        if (len > 3) {
          item[value] = str.substr(0, index + 3);
          return false;
        }
      }
      return true;
    },
    // 租凭商铺的时候赋值
    merchantsChange (list, itemArr, index) {
      this.shopLists.forEach((item) => {
        if (item.merchants_stall_id == list.merchants_stall_id) {
          if (itemArr[1]) {
            itemArr[1].pay_price = parseFloat(item.merchants_stall_price);
            this.calculate(itemArr[1], 1, itemArr, index);
          }
          list.pay_price = parseFloat(item.merchants_stall_price);
        }
      });
      this.initYearMonthFees();
    },
    // 新增/编辑合同
    submit () {
      this.form.merchants_stall = [];
      if (Array.isArray(this.progressiveIncreaseRate.cost)) {
        this.progressiveIncreaseRate.cost =
          this.progressiveIncreaseRate.cost.join("、");
      }
      this.form.contract_fee_setting.forEach((item, index) => {
        this.form.merchants_stall.push({
          merchants_stall_id: item[0].merchants_stall_id,
        });
        //将里面所有pay_price转换为数值型
        item.forEach((ite) => {
          ite.pay_price *= 1;
          ite.month_pay_price *= 1;
        });
        item[0].month_pay_price = item[1].pay_price;
      });
      if (this.form.contract_type == 10) {
        this.form.contract_no = "";
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type) {
            this.form.parent_id = this.id;
          }
          // 续签合同判断固定缴费是否有重新缴费如果没有去掉缴费项目
          if (this.type == "renew") {
            if (this.form.dcList) {
              let arr = [];
              this.form.dcList.forEach((item) => {
                if (item.statused == true) {
                  item.bill_status = 10;
                } else {
                  item.bill_status = 0;
                }
                if (item.pay_price) {
                  arr.push(item);
                }
              });
              this.form.dcList = arr;
            }
          }
          if (!this.paymentAmountSum())
            return this.$common.notifyError("缴费金额应等于应缴总金额");
          this.form.contractRentInfo.forEach((item) => {
            item.pay_price = item.pay_price.toString();
            if (item.statused == true) {
              item.bill_status = 10;
            } else {
              item.bill_status = 0;
            }
          });
          // this.form.contract_increasing = JSON.stringify(
          //   this.progressiveIncreaseRate
          // );
          this.form.contract_rent_info = JSON.stringify(
            this.form.contractRentInfo
          );
          this.iscomplete = true;

          this.$request
            .HttpPost("/contract/add", this.form)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.$router.push("/contract/contractlist");
            })
            .finally(() => {
              this.iscomplete = false;
            });
        }
      });
    },
    // 选择商户获取对应的身份证号码
    changeMerchants (id) {
      this.merchantsList.forEach((item) => {
        if (item.merchants_id == id) {
          this.iDNumber = item.merchants_card_id_original;
          return;
        }
      });
    },
    // 缴费次数下面的缴费金额计算不能大于总缴费金额
    paymentAmount (item) {
      // let total = 0;
      // for (let i in this.form.contractRentInfo) {
      //   let price = this.form.contractRentInfo[i].pay_price || 0;
      //   total += parseFloat(price) * 100;
      // }
      // if ((total / 100) > this.form.total_price) {
      //   this.$common.notifyError('缴费金额不能大于应缴总金额');
      //   // item.pay_price = '';
      // }
      // if(!this.paymentAmountSum()){
      //   this.$common.notifyError('缴费金额之和应等于应缴总金额');
      // }
    },
    // 提交计算总金额是佛与应缴相等
    paymentAmountSum () {
      let total = 0;
      for (let i in this.form.contractRentInfo) {
        let price = this.form.contractRentInfo[i].pay_price || 0;
        total += parseFloat(price) * 100;
      }
      if (total / 100 != this.form.total_price) {
        return false;
      }
      return true;
    },
    rentTotal (item, index) {
      if (item.contract_rent_total <= 0) {
        this.$message({
          message: "至少有一次缴费",
          type: "error",
        });
        item.contract_rent_total = 1;
      }
      if (item.contract_rent_total == this.form.contractRentInfo.length) {
        return;
      }
      this.calcAverage(item, index);
    },
    calcAverage (item, index) {
      item.contract_rentInfo = [];
      let total = item.total_pay_price;
      let times = item.contract_rent_total;
      let average = this.$common.getDecimalsOne(total / times);
      for (let i = 0; i < times; i++) {
        if (i < times - 1) {
          item.contract_rentInfo.push({
            pay_time: "",
            old_pay: "0",
            pay_price: average,
            contract_fee_name: "基础费用",
          });
        } else {
          if (average * times == total) {
            item.contract_rentInfo.push({
              pay_time: "",
              old_pay: "0",
              pay_price: average,
              contract_fee_name: "基础费用",
            });
          } else {
            item.contract_rentInfo.push({
              pay_time: "",
              old_pay: "0",
              pay_price: (total - average * (times - 1)).toFixed(2),
              contract_fee_name: "基础费用",
            });
          }
        }
      }
      this.$forceUpdate();
    },
    // 删除租凭信息
    delRental (index) {
      if (this.forbid) {
        return;
      }
      this.form.merchants_stall.splice(index, 1);
      let itemArr = this.form.contract_fee_setting.splice(index, 1);
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      time = time - (this.form.rent_holiday || 0);
      // for (let i in this.form.monthTotal) {
      //   let val =
      //     this.form.monthTotal[i].month_pay_price * 100 -
      //     itemArr[0][parseInt(i) + 1].pay_price * 100;
      //   this.form.monthTotal[i].month_pay_price = val / 100;
      //   this.form.monthTotal[i].total_pay_price = (val * time) / 100;
      // }
      this.calculateTotal();
    },
    // 添加租凭信息
    addRental () {
      if (this.forbid) {
        return;
      }
      if (!this.form.property_tpl_id) {
        this.$common.notifyError("请先选择合同模板");
        return;
      }
      let chargeList = [
        {
          merchants_stall_id: "",
          total_pay_price: 0,
          pay_price: 0,
          month_pay_price: 0,
        },
      ];
      this.contractTemplate.forEach((item) => {
        if (item.property_setting_id == this.form.property_tpl_id) {
          for (let i in this.contractChargeSetList) {
            if (
              item.values.chargingItemsId.indexOf(
                this.contractChargeSetList[i].id
              ) != -1 &&
              this.contractChargeSetList[i].type == 10
            ) {
              let str = this.contractChargeSetList[i];
              chargeList.push({
                ...str,
                pay_price: 0,
                total_pay_price: 0,
                month_pay_price: 0,
              });
            }
          }
        }
      });
      this.form.contract_fee_setting.push(chargeList);
    },
    // 根据选择的合同模板获取当前合同缴费项目,进行数据组装
    changeContract (id) {
      this.ContractID = id;
      let chargeList = [
        {
          merchants_stall_id: "",
          total_pay_price: 0,
          pay_price: 0,
          month_pay_price: 0,
        },
      ];
      if (!this.contractSpecial) {
        this.form.contract_fee_setting = [];
      }
      let fixationList = [];
      this.form.monthTotal = [];
      this.contractTemplate.forEach((item, index) => {
        if (item.property_setting_id == id) {
          chargeList[0].month_pay_price =
            this.contractChargeSetList[0].pay_price;
          for (let i in this.contractChargeSetList) {
            if (
              item.values.chargingItemsId.indexOf(
                this.contractChargeSetList[i].id
              ) != -1 &&
              this.contractChargeSetList[i].type == 10
            ) {
              chargeList.push({
                ...this.contractChargeSetList[i],
              });
              this.form.monthTotal.push({
                ...this.contractChargeSetList[i],
                totalMonery: "",
              });
            } else if (
              item.values.chargingItemsId.indexOf(
                this.contractChargeSetList[i].id
              ) != -1 &&
              this.contractChargeSetList[i].type == 20
            ) {
              fixationList.push(this.contractChargeSetList[i]);
            }
          }
        }
      });
      this.form.contract_fee_setting.push(chargeList);
      this.form.dcList = fixationList; //固定缴费费用
      this.form.dcList.forEach((item) => {
        if (item.bill_status == 10) {
          item.statuseds = true;
        } else {
          item.statuseds = false;
        }
      });
    },
    // 根据选择合同开始时间获取可以租聘的商铺
    contractStart (date, type) {
      this.form.rent_holiday = 0;
      this.dateedShop = [];
      if (date) {
        if (!this.contractSpecial) {
          this.form.contract_fee_setting = [];
        }
        // this.form.contract_fee_setting = []
        this.changeContract(this.ContractID);
        let params = {
          endTime: date,
        };
        if (this.id && this.type != "update") {
          params.contract_id = this.id;
        }
        this.$request
          .HttpGet("/merchants_stall/listAll", params)
          .then((res) => {
            if (res.data) {
              res.data.forEach((item) => {
                item.disabled = false;
                item.pitchVal = "";
              });
              this.dateedShop = res.data;
              // this.shopList = res.data.concat(this.purposeList);
              if (!type) {
                this.calculateTimeChange();
              }
            }
          });
      } else {
        this.shopList = [];
      }
    },
    rentHolidayBlur () {
      //  if (this.form.rent_holiday < 1) {
      //   this.form.rent_holiday = 0;
      // }
      if (!this.form.contract_start) {
        this.$common.notifyError("请选择合同开始时间");
        return;
      }
      if (!this.form.rent_holiday) {
        return;
      }

      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      if (parseInt(this.form.rent_holiday) < 0) {
        this.form.rent_holiday = 0;
        return false;
      }
      if (parseInt(this.form.rent_holiday) >= time) {
        this.$common.notifyError("免租期时长不能等于或者大于合同时长");
        this.form.rent_holiday = 0;
        return;
      }
      let date = this.$common.addMonth(
        this.form.contract_start,
        this.form.rent_holiday
      );
      this.form.contract_fee_start = this.form.contract_start;
      let end = this.$common.formatDateTime(date);
      this.$set(this.rent_holiday_date, 0, this.form.contract_start);
      this.$set(this.rent_holiday_date, 1, end);
      this.form.contract_fee_end = end;
      this.form.rent_holiday = parseInt(this.form.rent_holiday);
      this.calculateTimeChange("mrent");
      //  this.calcAllTotal()
    },
    // 获取合同收费设置项目列表
    getContractChargeSetList () {
      return this.$request
        .HttpPost("/contract_fee_setting/listAll")
        .then((res) => {
          if (res.data) {
            res.data.forEach((item) => {
              item.month_pay_price = 0;
              item.pay_time = "";
            });
            this.contractChargeSetList = res.data || [];
          }
        });
    },
    // 获取合同模板
    getContractTemplate () {
      return this.$request
        .HttpPost("/setting/list", {
          pageNum: 1,
          pageSize: 100000,
          key: "propertyTplSetting",
        })
        .then((res) => {
          if (res.data.list) {
            res.data.list.forEach((item) => {
              if (item.child_value) {
                item.child_value.values = JSON.parse(item.child_value.values);
              }
              item.values = JSON.parse(item.values);
            });
            this.contractTemplate = res.data.list;
          }
        });
    },
    getMerchantsList () {
      return this.$request
        .HttpPost("/merchants/list", {
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          this.merchantsList = res.data.list || [];
        });
    },
    isdyZero (rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入缴费金额"));
      }
      if (value <= 0) {
        return callback(new Error("缴费金额不能小于等于0"));
      }
      return callback();
    },
    checktpl (url) {
      let isUrl = url.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        window.open(process.env.VUE_APP_LOGIN + "/" + url);
      } else {
        window.open(url);
      }
    },

    // 每年费用
    // 渲染数据
    initYearMonthFees () {
      this.form.contract_increasing = [];
      // 根据年数显示每年每月费用
      for (let i = 0; i < this.years; i++) {
        this.form.contract_increasing.push({
          feesLit: [],
          total_pay_price: 0,
          contract_increasing: 0,
          type: 0,
        });
      }
      this.calceYearMonthFee();
    },
    // 添加每年每一项费用
    calceYearMonthFee () {
      // this.form.contract_increasing  递增年费用
      this.form.contract_increasing.forEach((item, index) => {
        let obj = {};
        let arr = this.form.contract_fee_setting;
        let tempArr = [];
        arr.forEach((items, indexs) => {
          items.forEach((its, ins) => {
            if (ins > 0) {
              tempArr.push(its);
              if (!obj[its.id]) {
                obj[its.id] = 0;
              }
              obj[its.id] += Number(its.pay_price);
            }
          });
        });

        for (let i in obj) {
          item.feesLit.push({
            pay_price: obj[i].toString(),
            original_pay_price: obj[i].toString(),
            name: tempArr.filter((item) => item.id == i)[0].contract_fee_name,
            id: i,
          });
        }
      });
      this.rateYearMonthFee();
    },
    // 递增率计算
    rateYearMonthFee () {
      // this.form.monthTotal 月总基础费用
      let mainarr = this.form.contract_increasing;
      mainarr.forEach((fir, firindex) => {
        let total = 0;
        // 自增率
        if (fir.contract_increasing < 0) {
          fir.contract_increasing = 0;
        }

        if (firindex > 0) {
          let data = this.form.contract_increasing[firindex - 1].feesLit;
          for (let i in fir.feesLit) {
            fir.feesLit[i].pay_price = data[i].pay_price;
            fir.feesLit[i].original_pay_price = data[i].pay_price;
          }
        }

        fir.month_pay_price = 0;
        if (fir.type == "0") {
          // 全部递增
          fir.feesLit.forEach((list) => {
            list.pay_price = (
              list.pay_price *
              (1 + fir.contract_increasing / 100)
            )
              .toFixed(2)
              .toString();
          });
        } else {
          fir.feesLit.forEach((list) => {
            if (list.id == fir.type) {
              list.pay_price = (
                list.original_pay_price *
                (1 + fir.contract_increasing / 100)
              )
                .toFixed(2)
                .toString();
            } else {
              list.pay_price = list.original_pay_price.toString();
            }
          });
        }
        // if (firindex > 0) {
        //   fir.month_pay_price = 0;
        //   let list = this.form.contract_increasing[firindex - 1].feesLit; //每年收费项
        //   fir.feesLit.forEach((item) => {
        //     list.forEach((items) => {
        //       // 全部递增
        //       if (items.id == item.id && fir.type == 0) {
        //         item.pay_price = Number(
        //           (
        //             items.pay_price *
        //             (1 + fir.contract_increasing / 100)
        //           ).toFixed(2)
        //         );
        //       }
        //       //单独递增
        //       if (fir.type != 0 && items.id == item.id && fir.type == item.id) {
        //         item.pay_price = Number(
        //           (
        //             items.pay_price *
        //             (1 + fir.contract_increasing / 100)
        //           ).toFixed(2)
        //         );
        //       }
        //     });
        //     item.pay_price = this.$common.getDecimalsOne(item.pay_price)
        //   });
        // }
        let time = this.$common.monthsBetw(
          this.form.contract_start,
          this.form.contract_end
        );
        time = time - (this.form.rent_holiday || 0);
        fir.feesLit.forEach((item) => {
          total += Number(item.pay_price);
          fir.month_pay_price += Number(item.pay_price);
        });
        fir.month_pay_price = this.$common.getDecimalsOne(fir.month_pay_price);
        if (
          time % 12 > 0 &&
          mainarr.length > 1 &&
          firindex == mainarr.length - 1
        ) {
          // 最后一年通过月计算
          fir.feesLit.forEach((value) => {
            value[value.name] = this.$common.getDecimalsOne(
              Number((time % 12) * value.pay_price)
            );
          });
          fir.total_pay_price = this.$common.getDecimalsOne(
            Number((time % 12) * total)
          );
        } else {
          // 全年
          fir.total_pay_price = this.$common.getDecimalsOne(Number(total * 12));
          fir.feesLit.forEach((value) => {
            value[value.name] = this.$common.getDecimalsOne(
              Number(12 * value.pay_price)
            );
          });
        }
      });
      this.calculateTotal();
      this.calcAllTotal();
      this.calcMonthTotal();
    },
    // 计算月和总的总金额
    calculateTotal () {
      let monthVal = 0;
      let totalVal = 0;
      this.form.monthTotal.forEach((item) => {
        monthVal += parseFloat(item.month_pay_price * 100);
        totalVal += parseFloat(item.total_pay_price * 100);
      });
      this.form.month_price = this.$common.getDecimalsOne(monthVal / 100);
      this.form.total_price = this.$common.getDecimalsOne(totalVal / 100);
      // this.calcAverage();
    },
    // 通用计算总金额
    calcAllTotal () {
      let total = 0;
      let arr = this.form.contract_increasing;
      if (arr) {
        arr.forEach((item, index) => {
          total += Number(item.total_pay_price);
        });
      }
      this.form.total_price = this.$common.getDecimalsOne(total);
    },
    // 计算租期内金额
    calcMonthTotal () {
      let yearFee = this.form.contract_increasing;
      let monthFee = this.form.monthTotal;
      if (monthFee) {
        monthFee.forEach((item) => {
          item.total_pay_price = 0;
          yearFee.forEach((items) => {
            if (items.feesLit) {
              items.feesLit.forEach((ite) => {
                if (item.contract_fee_name == ite.name) {
                  item.total_pay_price += Number(ite[ite.name]);
                }
              });
            }
          });
          item.total_pay_price = this.$common.getDecimalsOne(
            item.total_pay_price
          );
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.addcontract {
  // height: calc(100vh - 117px);
  overflow: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-top: 15px;
}

.labelwidth {
  width: 400px !important;
}

.inputWidth {
  width: 250px !important;
}

.rentalBox {
  display: flex;
  align-items: center;

  .right {
    margin-left: 30px;
  }
}

.upload-demo {
  /deep/ .el-upload {
    text-align: left;
  }
}

/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
