<template>
  <div class="templateField">
    <el-divider content-position="left">固定缴费费用</el-divider>
    <div v-for="(item, index) in dcList.dcList" :key="index">
      <el-row>
        <el-col :span="5">
          <el-form-item :label="item.contract_fee_name">
            <el-input v-model="item.pay_price" :disabled="disabled" :placeholder="`请输入${item.contract_fee_name}`"
              class="public-input"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="`${item.contract_fee_name}缴费时间`">
            <el-date-picker class="public-input" :disabled="disabled" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
              v-model="item.pay_time" placeholder="缴费时间"></el-date-picker>
            <!-- <el-checkbox v-model="item.statuseds" :disabled="forbid" @change="checked($event,item)">已缴费</el-checkbox> -->
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="`已缴${item.contract_fee_name}`">
            <el-input-number placeholder="请输入已缴金额" :disabled="disabled" :controls="false" :min="0"
              v-model="item.paid_amount" class="public-input"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="`实际缴费时间`">
            <el-date-picker class="public-input" format="yyyy-MM-dd" :disabled="disabled" :clearable="false"
              v-model="item.paid_time" value-format="yyyy-MM-dd" placeholder="缴费时间"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'templateField',
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false;
      }
    },
    dcList: {
      type: Object,
      default () {
        return {};
      }
    },
    list: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      bill_status: false,
      forbid: false
    };
  },
  created () {
    this.forbid = this.$route.query.forbid ? true : false;
    if (this.dcList.dcList) {
      this.dcList.dcList.forEach(item => {
        if (item.bill_status == 10) {
          item.statuseds = true;
        } else {
          item.statuseds = false;
        }
      });
    }
  },
  methods: {
    // setSelect(item){
    //   console.log(item);
    // }
    checked (e, item) {
      console.log(this.dcList);
      if (e) {
        item.bill_status = 10
      } else {
        item.bill_status = 0
      }
      this.$forceUpdate()
    }
  }
};
</script>
<style lang="scss" scoped></style>
